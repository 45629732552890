const platformConfig = require(`./${process.env.REACT_APP_PLATFORM}.json`)
const apiBaseUri = process.env.REACT_APP_API_BASE_URI
console.log({ apiBaseUri })
export default {
  ...platformConfig,
  api: {
    uri: `${apiBaseUri}/graphql`,
    hooksUri: `${apiBaseUri}/hooks`,
    authUri: `${apiBaseUri}/auth`,
    uploadUri: `${apiBaseUri}/upload`,
    uploadSignatureUri: `${apiBaseUri}/upload/sign`,
    s3UploadSignatureUri: `${apiBaseUri}/s3upload/sign`
  }
}
