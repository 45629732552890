import { compose } from 'recompose'
import { graphql } from '@apollo/client/react/hoc'
import currentUserQuery from '~/App/graphql/queries/currentUser.gql'

const withUser = ({ refetch } = { refetch: true }) => compose(
  graphql(currentUserQuery, {
    options: {
      fetchPolicy: refetch ? 'network-only' : 'cache-first'
    },
    props: ({ data: { loading, currentUser } }) => ({
      currentUser,
      currentUserLoading: loading
    })
  })
  // branch(
  //   ({ currentUserLoading }) => currentUserLoading,
  //   renderNothing
  // )
)

export default withUser
