import React from "react";
// import { compose } from 'recompose'
import {compose} from "recompose"
import { withRouter } from 'react-router'
import { TabBar } from 'antd-mobile'
import {
  AppOutline,
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons'

const Navigation = (props) => {
	console.log(props, 'navi');
	const { pathname } = props.location.pathname
	const setRouteActive = (value) => {
		props.history.push(value)
		console.log(value,'value');
	}
	const tabs = [
		{
			key: '/',
			title: 'Home',
			icon: <AppOutline />,
		},
		{
			key: '/dashboard',
			title: 'History',
			icon: <UnorderedListOutline />,
		},
		{
			key: '/profile/edit',
			title: 'Profile',
			icon: <UserOutline />,
		},
	]

  return(
		<div>
		<TabBar style={{height : '65px'}} activeKey={pathname} onChange={value => setRouteActive(value)}>
 			{tabs.map(item => (
				<TabBar.Item 
					key={item.key}
					icon={item.icon}
					title={item.title}
				/>
			))}
		</TabBar>
		</div>
	)
}

export default compose(
	withRouter
) (Navigation)