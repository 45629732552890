import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from "recompose"
import { withApollo } from "@apollo/client/react/hoc"
import { FormattedMessage , injectIntl } from 'react-intl'
import { Form, Modal, Tabs, Button } from 'antd'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { OrangeButton } from '~/styles'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faUserCircle
// } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import { 
  logoutUser, 
  // getAuthUri 
} from '../../auth'
import { inject, observer } from 'mobx-react';

// const FormItem = Form.Item

// const currentUser = gql`
//   query currentUser {
//     currentUser {
//       _id
//       email
//       profile {
//         firstName
//         lastName
//       }
//     }
//   }
// `

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  span.ant-modal-close-x {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
  }
`;
@inject('i18n')
@observer
@injectIntl
class AuthModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    redirectTo: PropTypes.string
  };
  state = {
    acceptedPrivacyPolicy: false
  };

  handleLogout = () => {
    logoutUser().then(() => {
      this.props.client.resetStore()
      this.props.history.push('/')
    })
  }
  handleAcceptPrivacyPolicy = () => {
    this.setState({ acceptedPrivacyPolicy: true });
    this.props.onClose();
    this.props.handleAcceptPolicy();
    this.props.history.push('/');
  }
  render () {
    // const { getFieldDecorator } = this.props.form
    const { intl } = this.props
    const UA = navigator.userAgent || navigator.vendor || window.opera

    const isLINE = function (UA) {
      return (UA.indexOf('Line/') > -1)
    }
    let redirectTo = (isLINE(UA) ? (this.props.redirectTo || '') + '&&openExternalBrowser=1' : this.props.redirectTo) || ''
    const acceptedBlock = <div>
      <h2>
      {/* ข้อตกลงการใช้งานระบบ (Term Of Use) */}
      Term Of Use
      </h2>
      {/* ฉันยอมรับ */}
      {/* {locale === 'th'
        ? 'ฉันยอมรับข้อตกลงการใช้งานและอ่านทำความเข้าใจนโยบายของระบบ'
        : 'I accept the terms of use and read the privacy policy.'} */}
      {' '}
      {/* <Link to="privacy-policy" onClick={this.props.onClose}>" */}
        {/* {locale === 'th'
          ? 'ข้อตกลงการใช้งาน'
          : 'Privacy Policy'} */}
          {/* ข้อตกลงการใช้งาน */}
        {/* "</Link> */}
        {/* <br /> */}
        I accept the terms of use
        {' '}
        <Link to="privacy-policy" onClick={this.props.onClose}>"
        {/* {locale === 'th'
          ? 'ข้อตกลงการใช้งาน'
          : 'Privacy Policy'} */}
          Privacy Policy
        "</Link>
        <br />
      <div style={{textAlign: 'right'}}>
        <Button onClick={this.props.onClose}>
          <FormattedMessage id="app.rejectCookie"/>
        </Button>{' '}
        <OrangeButton onClick={() => this.setState({acceptedPrivacyPolicy: true})} style={{ padding: '5px 15px', margin: '0 5px' }}>
          <FormattedMessage id="app.acceptCookie"/>
        </OrangeButton>
      </div>
    </div>
    const AuthForm = <div>
      <div className="loginModalHeader">
        <img src="/user.png" style={{ width: '100%', maxWidth: '90px' }} alt="logo" />
        {/* <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '80px', color: '#3a559f' }}/> */}
      </div>
      <Tabs style={{ textAlign: 'center' }}>
        <Tabs.TabPane tab={intl.formatMessage({ id : "app.login"})} key="login">
          {/* <a href={getAuthUri('facebook', { redirectTo })}>
            <Row className="facebookBox">
              <Col span={2} >
                <img src="/FacebookNew.png" style={{ width: '100%', maxWidth: '30px' }} alt="logo" />
              </Col>
              <Col span={22} className="facebookText">
                {intl.formatMessage({ id : "app.facebook.login"})}
              </Col>
            </Row>
          </a> */}
          <LoginForm onClose={this.props.onClose} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage({ id : "app.register.system"})} key="register">
          {/* <div className="facebookBox">
        <span className="facebookText">
          {({
            en: `Signup with Facebook`,
            zh: `Signup with Facebook`,
            th: `สมัครผ่าน Facebook`
          })[locale]}
        </span>
      </div> */}
          <RegisterForm onClose={this.props.onClose} redirectTo={redirectTo}/>
        </Tabs.TabPane>
      </Tabs>
    </div>
    return (
      <StyledModal
        title={null}
        style={{
          top: 10,
          borderRadius: 10
        }}
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.props.onClose}
        cancelText="Cancel"
        okText="Login"
        footer={null}
      >
        {this.state.acceptedPrivacyPolicy
          ? AuthForm
          : acceptedBlock}
      </StyledModal>
    )
  }
}

export default compose(
  Form.create(),
  withApollo,
  withRouter // TODO: should put router only inside auth wrapper
)(AuthModal);
