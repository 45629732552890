import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from "recompose"
import { withApollo } from "@apollo/client/react/hoc"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { SafeArea } from 'antd-mobile'
import {
  Form,
  // Menu,
  // Dropdown,
  Icon,
  Select,
  Drawer,
  Popover
} from 'antd'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { inject, observer } from 'mobx-react'
import _get from 'lodash/get'
import './style.css'
import {
  logoutUser
} from '../../auth'

// import { HeaderWrapper } from '~/styles'
// import SearchEvent from './search'
import styled from 'styled-components'
import platformObj from '~/config'
// import { Capacitor } from '@capacitor/core'
import {
  HeaderWrapper,
  HeaderStyles,
  HeaderBoxStyles,
  HeaderIconStyles,
  HeaderDropDownStyles,
  OrangeButton
} from '~/styles'
import Profile from '../../App/DashboardPage/Components/Profile'
// import AuthModal from '../AuthModal';
const UA = navigator.userAgent || navigator.vendor || window.opera
const isLINE = UA.indexOf('Line/') > -1
// const { Search } = Input
const locales = {
  en: '🇺🇸 EN',
  // th: '🇹🇭 TH',
  // ms: '🇲🇾 MS'
  // zh: '🇨🇳 CN',
  id: '🇮🇩 ID'
}

const StyledSelect = styled(Select)`
  // .ant-select-selection {
  //   border: 2px solid #fff !important;
  //   border-radius: 20px;
  //   box-shadow: none !important;
  //   background-color: rgb(28, 129, 196);
  //   color: #fff;
  // }
  // .ant-select-arrow {
  //   color: #fff;
  // }
  .ant-select-selection {
    // border: 2px solid #1D81C4 !important;
    border: none;
    border-radius: 20px;
    box-shadow: none !important;
    background-color: #fff;
    color: #1d81c4;
    .ant-select-selection__rendered {
      margin-right: 11px;
    }
  }
  .ant-select-arrow {
    display: none;
    color: #1d81c4;
  }
`

const StyledMobileSelect = styled(Select)`
  .ant-select-selection {
    border: none;
    box-shadow: none !important;
    background-color: none;
    color: #000;
    font-weight: 500;
  }
  .ant-select-arrow {
    color: #000;
    font-weight: 500;
    right: 20px;
  }
`
// const Select = styled(Select.Option)`
//   .ant-select-selection {
//     border: none !important;
//   }
// `
const { Option } = Select
class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      toggle: false,
      visible: false,
      menuVisible: false,
      loginLoading: false,
      signupLoading: false,
      checkScroll: 0
      // acceptedPolicy :false,
      // showAuthModal:false
    }
    this.clickMenu = this.clickMenu.bind(this)
    this.showLoginModal = this.showLoginModal.bind(this)
    this.cancelLogin = this.cancelLogin.bind(this)
  }
  // TODO: move auth logic outside Header
  showDrawer = () => {
    this.setState({
      visible: true
    })
  };

  onClose = () => {
    this.setState({
      visible: false
    })
  };
  hideMenu = () => {
    this.setState({
      menuVisible: false
    })
  };
  handleMenuVisibleChange = (menuVisible) => {
    this.setState({ menuVisible })
  };

  handleLogout = () => {
    // console.log('Logout')
    logoutUser().then(() => {
      this.props.client.resetStore()
      this.props.history.push('/')
    })
  };

  showLoginModal () {
    this.setState({
      visible: true
    })
  }

  componentDidMount () {
    if (window) {
      window.addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount () {
    if (window) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  handleScroll = (e) => {
    if (window && document) {
      // let display = e.srcElement.body.scrollTop
      // let header = document.querySelector('.ant-layout-header')
      // if (display <= 0) {
      //   header.classList.remove('onScoll')
      // } else if (display > this.state.checkScroll) {
      //   header.classList.add('onScoll')
      // } else {
      //   header.classList.remove('onScoll')
      // }
      // this.setState({
      //   checkScroll: display
      // })
    }
    // if (display !== 0) {
    //   header.classList.add('onScoll')

    // } else {
    //   header.classList.remove('onScoll')
    // }
  };
  clickMenu () {
    // console.log(this.state.toggle)
    if (window && document) {
      let header = document.querySelector('.ant-layout-header')
      let dropdown = document.querySelector('.dropDown')
      if (!this.state.toggle) {
        header.classList.add('clickMenu')
        dropdown.classList.remove('hideDropDown')
        this.setState({ toggle: true })
      } else {
        header.classList.remove('clickMenu')
        dropdown.classList.add('hideDropDown')
        this.setState({ toggle: false })
      }
    }
  }
  clickDropDownMenuItem () {
    window.scrollTo(0, 0)
    if (window && document) {
      let header = document.querySelector('.ant-layout-header')
      let dropdown = document.querySelector('.dropDown')
      if (!this.state.toggle) {
        header.classList.add('clickMenu')
        dropdown.classList.remove('hideDropDown')
        this.setState({ toggle: true })
      } else {
        header.classList.remove('clickMenu')
        dropdown.classList.add('hideDropDown')
        this.setState({ toggle: false })
      }
    }
  }
  cancelLogin () {
    this.setState({
      visible: false
    })
  }

  handleLanguageChange (locale) {
    const { i18n } = this.props
    i18n.setLocale(locale)
  }

  // handleAcceptPolicy = () => {
  //   this.setState({ acceptedPolicy: true });
  // };

  // showAuthModal =()=>{
  //   // console.log("Hello");
  //   // console.log(this.state);
  //   this.setState({showAuthModal: true})
  // }

  // handleSignInSignOutBtn =()=>{

  //   if(this.state.acceptedPolicy){
  //     this.props.history.push("/login")

  //     return
  //   }

  //   this.showAuthModal()

  // }

  // hideAuthModal=()=>{
  //   this.setState({showAuthModal:false})
  // }

  // render () {
  //   const { currentUser, i18n } = this.props
  //   const name = currentUser?.profile?.firstName
  //   const email = currentUser?.email
  //   const telephone = currentUser?.profile?.telephone
  //   const displayName = [name, email, telephone].find(
  //     (el) => el && el !== undefined
  //   )
  //   let isLineUser = false
  //   const handleGoPricing = () => {
  //     window.scrollTo(0, 0)
  //     const { history } = this.props
  //     history.push('/pricing')
  //   }
  //   const isMobile = window.innerWidth <= 768
  //   // let isLineUser = isLINE
  //   if (
  //     currentUser &&
  //     currentUser.email?.search('raceline+') > -1 &&
  //     currentUser.email?.search('@thai.run') > -1
  //   ) {
  //     if (isLINE) {
  //       isLineUser = true
  //     }
  //   }
  //   const locale = i18n.locale
  //   const isNative = Capacitor.isNativePlatform()
  //   // const osNative = Capacitor.getPlatform()
  //   const { pathname } = window.location
  //   if (isLineUser) {
  //     return (
  //       <HeaderWrapper style={{ minWidth: '-webkit-fill-available' }}>
  //         <div className="header" style={{isNative}}>
  //           <HeaderStyles>
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 justifyContent: 'flex-start',
  //                 alignItems: 'center',
  //                 width: '50%'
  //               }}>
  //               <Link to="/" onClick={() => window.scrollTo(0, 0)}>
  //                 <img
  //                   src="/logothairun-(W).png"
  //                   style={{
  //                     width: '100%',
  //                     maxWidth: '70px',
  //                     marginLeft: '10px'
  //                   }}
  //                   alt="logo"
  //                 />
  //               </Link>
  //             </div>
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 justifyContent: 'flex-end',
  //                 alignItems: 'center',
  //                 width: '50%'
  //               }}>
  //               <HeaderIconStyles>
  //                 <i
  //                   style={{ color: '#DADADA', padding: '10px' }}
  //                   className="material-icons"
  //                   onClick={this.showDrawer}>
  //                   dehaze
  //                 </i>
  //                 <Drawer
  //                   width="100%"
  //                   onClose={this.onClose}
  //                   visible={this.state.visible}
  //                   placement="top"
  //                   // style={{
  //                   //   backgroundColor: '#1c81c4'
  //                   // }}
  //                   // bodyStyle={{ paddingBottom: 80 }}
  //                 >
  //                   <HeaderDropDownStyles style={{ padding: '0 40px' }}>
  //                     <span style={{ padding: '10px' }}>
  //                       {currentUser && [
  //                         <Link
  //                           to="/dashboard"
  //                           key="dashboard"
  //                           className="menuItem baseBtn"
  //                           style={{
  //                             border: '2px solid #fff',
  //                             backgroundColor: 'white',
  //                             color: 'black',
  //                             fontWeight: '400'
  //                           }}>
  //                           <Icon
  //                             type="idcard"
  //                             style={{
  //                               marginRight: 10,
  //                               fontSize: 20,
  //                               fontWeight: 500
  //                             }}
  //                           />
  //                           <FormattedMessage
  //                             id="app.myRegistrations"
  //                             defaultMessag="My Registrations"
  //                           />
  //                         </Link>
  //                       ]}
  //                     </span>

  //                     <div
  //                       style={{
  //                         width: '300px',
  //                         borderBottom: '1px solid #dfe3e8'
  //                       }}
  //                     />
  //                     <span style={{ padding: '10px' }}>
  //                       <StyledMobileSelect
  //                         defaultValue={this.props.i18n.locale}
  //                         style={{
  //                           width: 90
  //                         }}
  //                         onChange={(locale) =>
  //                           this.handleLanguageChange(locale)
  //                         }>
  //                         {platformObj.localeList.map((key, i) => (
  //                           <Option key={key} value={key}>
  //                             <img src={'/' + key + '.svg'} alt={key} style={{ transform: 'translateY(-1px)', height: '20px', marginTop: 4 }} />
  //                           </Option>
  //                         ))}
  //                       </StyledMobileSelect>
  //                     </span>
  //                     <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
  //                   </HeaderDropDownStyles>
  //                 </Drawer>
  //               </HeaderIconStyles>
  //             </div>
  //           </HeaderStyles>
  //         </div>
  //       </HeaderWrapper>
  //     )
  //   }
  //   // const menu = (
  //   //   <Menu>
  //   //     <Menu.Item key="menu1">
  //   //       <Link to="/dashboard">Profile</Link>
  //   //     </Menu.Item>
  //   //     <Menu.Divider />
  //   //     <Menu.Item key="menu4">
  //   //       <div onClick={this.handleLogout}>
  //   //         <FormattedMessage id="app.logout"/>
  //   //       </div>
  //   //     </Menu.Item>
  //   //   </Menu>
  //   // )
  //   return (

  //     <HeaderWrapper style={{ minWidth: '-webkit-fill-available' }}>
  //       {/* <AuthModal
  //             visible={this.state.showAuthModal}
  //             onClose={this.hideAuthModal}
  //             handleAcceptPolicy={this.handleAcceptPolicy}
  //             redirectTo={this.state.redirectTo}
  //           /> */}
  //       <div className="header">
  //         {/* <div style={{ background: '#ace0ff' }}> */}
  //           <div >
  //             <SafeArea position='top' /> 
  //           </div>
            
  //           <HeaderStyles style={{ 
  //               position:isNative?"relative":"", 
  //               display:"flex", 
  //               justifyContent:"center" ,
  //               alignItems:"center"
  //               }}>
  //             <div style={{
  //               display: 'flex', 
  //               justifyContent: isNative ? 'center' : 'flex-start', 
  //               alignItems: 'center', 
  //               width: isNative ? '' : '50%',
                
  //             }}
                
  //               >
  //               { isNative && !['/', '/dashboard', '/profile/edit'].includes(pathname) ? 
  //               <span onClick={() => window.history.back()} 
  //                     style={{
  //                       // border: 'none', 
  //                       display:"flex",
  //                       alignItems:"center",
  //                       position: 'absolute',
  //                       textAlign:"center" , 
  //                       top:0 , 
  //                       bottom:0 ,
  //                       left:0,
  //                       margin:"auto",
  //                       verticalAlign:"middle",
  //                     }}
  //                     >
  //                 <Icon style={{ fontSize: '20px', color: '#333333'}} type="left" />
  //               </span> : '' }
  //               <Link to="/" onClick={() => window.scrollTo(0, 0)}>
  //                 <img 
  //                 src={ platformObj.logo.url } 
  //                 style={ isNative ? { width: '100%', maxWidth: '70px' , marginBottom:"1%"} 
  //                     :['/', '/dashboard', '/profile/edit'].includes(pathname) ? { maxHeight: '50px'} 
  //                     : { maxHeight: '50px', marginLeft: '10px' }} 
  //                     alt="logo" />
  //               </Link>
  //             </div>
  //             <div style={{
  //               position:isNative? "absolute" :"", 
  //               top:0,
  //               bottom:0, 
  //               right:0 ,
  //               textAlign:isNative?"center":"", 
  //               display: 'flex', 
  //               justifyContent: 'flex-end', 
  //               alignItems: 'center', 
  //               width: isNative?"":'50%', 
  //               verticalAlign:"middle",
  //               paddingTop: isNative ? '0px' : '0px'}}>
  //               {isMobile && (
  //                 <StyledSelect
  //                 defaultValue={this.props.i18n.locale}
  //                 bordered={false}
  //                 onChange={(locale) => this.handleLanguageChange(locale)}
  //                 dropdownMatchSelectWidth = {false}
  //               >
  //                   {platformObj.localeList.map((key, i) => (
  //                     <Option key={key} value={key}>
  //                       <img src={'/'+key+'.png'} alt={key} style={{ transform: 'translateY(-1px)', width:'22px', height: '13px' }} />{' '}
  //                       <span>{key.toUpperCase()}</span>
  //                     </Option>
  //                   ))}
  //                 </StyledSelect>
  //               )}
  //               <HeaderBoxStyles>
  //               <StyledSelect
  //                 defaultValue={this.props.i18n.locale}
  //                 bordered={false}
  //                 onChange={(locale) => this.handleLanguageChange(locale)}
  //                 dropdownMatchSelectWidth = {false}
  //               >
  //                   {platformObj.localeList.map((key, i) => (
  //                     <Option key={key} value={key}>
  //                       <img src={'/'+key+'.png'} alt={key} style={{ transform: 'translateY(-1px)', width:'22px', height: '13px' }} />{' '}
  //                       <span>{key.toUpperCase()}</span>
  //                     </Option>
  //                   ))}
  //                 </StyledSelect>
  //                 {currentUser
  //                   ? (
  //                     <Popover placement="bottomRight" content={<Profile header />} trigger="click">
  //                       <OrangeButton type="secondary" style={{ display: 'flex', alignItems: 'center', padding: '5px 15px' }}>
  //                         <p style={{ margin: '0 30px 0 0' }}>
  //                           {displayName}
  //                           {/* {currentUser.profile.firstName} {_get(currentUser, 'profile.lastName.0', '')}. */}
  //                         </p>
  //                         <FontAwesomeIcon icon={faAngleDown} />
  //                       </OrangeButton>
  //                     </Popover>
  //                   )
  //                   : (
  //                     <>
  //                       {(pathname !== '/login' && pathname !== '/signup') && (
  //                         <OrangeButton onClick={() => this.props.history.push('/login')} style={{ padding: '5px 15px', margin: '0 5px' }}>
  //                           <FormattedMessage id="app.loginOrRegister"/>
  //                         </OrangeButton>)}
  //                       { process.env.REACT_APP_PLATFORM === 'thai' &&
  //                       <OrangeButton onClick={handleGoPricing} style={{ padding: '5px 15px', margin: '0 5px' }}>
  //                         <FormattedMessage id="app.pricing"/>
  //                       </OrangeButton> }
  //                     </>
  //                   )
  //                 }
  //               </HeaderBoxStyles>
  //               {!isNative ? <HeaderIconStyles>
  //                 <Popover placement="bottomRight"
  //                   visible={this.state.menuVisible}
  //                   onVisibleChange={this.handleMenuVisibleChange}
  //                   content={
  //                     <Profile onAuthClick={() => this.props.history.push('/login')}
  //                       hideMenu={this.hideMenu}
  //                       header history={this.props.history}/>
  //                   } trigger="click">
  //                   <i style= {{ color: '#DADADA', padding: '10px' }} className="material-icons" onClick={this.showDrawer}>dehaze</i>
  //                 </Popover>
  //                 {/* <i style= {{ color: '#DADADA', padding: '10px' }} className="material-icons" onClick={this.showDrawer}>dehaze</i> */}
  //                 {/* <Drawer
  //                   width='100%'
  //                   onClose={this.onClose}
  //                   visible={this.state.visible}
  //                   placement='top'
  //                   // style={{
  //                   //   backgroundColor: '#1c81c4'
  //                   // }}
  //                   // bodyStyle={{ paddingBottom: 80 }}
  //                 >
  //                   <HeaderDropDownStyles style={{ padding: '0 40px' }}>
  //                     <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
  //                     <span style={{ padding: '10px' }}>
  //                       {currentUser
  //                         ? <div className="menuItem" style={{ border: '2px solid #fff', borderRadius: '20px' }}>
  //                           <Dropdown overlay={menu} trigger={['click']}>
  //                             <span className="ant-dropdown-link login_btn baseBtn logout" style={{ backgroundColor: 'white', color: 'black' }}>
  //                               <Icon type="user" style={{ marginRight: 10, fontSize: 18, fontWeight: 500 }} />
  //                               {currentUser.profile.firstName} {_get(currentUser, 'profile.lastName.0', '')}. <Icon type="down" />
  //                             </span>
  //                           </Dropdown>
  //                         </div>
  //                         : <div className="menuItem">
  //                           <div
  //                             className="login_btn baseBtn menuItem"
  //                             style={{
  //                               width: '220px',
  //                               border: '2px solid #fff',
  //                               backgroundColor: 'white',
  //                               color: 'black'
  //                             }}
  //                             onClick={this.props.onAuthClick}
  //                           >
  //                             <Icon type="logout" style={{ marginRight: 10, fontSize: 18, fontWeight: 500 }} />
  //                             <FormattedMessage id="app.loginOrRegister"/>
  //                           </div>
  //                         </div>
  //                       }
  //                     </span>
  //                     <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
  //                     <span style={{ padding: '10px' }}>
  //                       <div className="menuItem">
  //                         <div
  //                           className="login_btn baseBtn menuItem"
  //                           style={{
  //                             width: '220px',
  //                             border: '2px solid #fff',
  //                             backgroundColor: 'white',
  //                             color: 'black'
  //                           }}
  //                           onClick={() => this.handleGoPricing()}
  //                         >
  //                           <Icon type="unordered-list" style={{ marginRight: 10, fontSize: 18, fontWeight: 500 }} />
  //                           <FormattedMessage id="app.pricing"/>
  //                         </div>
  //                       </div>
  //                     </span>
  //                     <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
  //                     <span style={{ padding: '10px' }}>
  //                       <StyledMobileSelect
  //                         defaultValue={this.props.i18n.locale}
  //                         style={{
  //                           width: 90
  //                         }}
  //                         onChange={(locale) => this.handleLanguageChange(locale)}
  //                       >
  //                         {Object.entries(locales).map(([key, label], i) => (
  //                           <Option key={key} value={key}>
  //                             <img src={'/' + key + '.svg'} alt={key} style={{ transform: 'translateY(-1px)', height: '20px', marginTop: 4 }} />
  //                           </Option>
  //                         ))}
  //                       </StyledMobileSelect>
  //                     </span>
  //                     <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
  //                   </HeaderDropDownStyles>
  //                 </Drawer> */}
  //               </HeaderIconStyles> : '' }
  //             </div>
  //           </HeaderStyles>
  //         {/* </div> */}
  //       </div>
  //       <div className="dropDown hideDropDown">
  //         <div className="menuList">
  //           <Link to="/" key="races" onClick={() => this.clickDropDownMenuItem()} className="menuItem">
  //             <FormattedMessage id="app.races"/>
  //           </Link>
  //         </div>
  //         {/* {currentUser &&
  //                 <div className="menuList">
  //                   <Link to="/dashboard" key="dashboard" onClick={() => this.clickDropDownMenuItem()} className="menuItem">
  //                     <FormattedMessage id="app.myRegistrations" defaultMessag="My Registrations" />
  //                   </Link>
  //                 </div>} */}
  //         <div className="menuList">
  //           <span className="localeSwitcher menuItem" >
  //             {platformObj.localeList.map((key, i) => (
  //               <span key={key}>
  //                 {i !== 0 && <span className="localeSeparator" />}
  //                 <span
  //                   onClick={() => this.handleLanguageChange(key)}
  //                   className={key === locale ? 'active locale' : 'locale'}
  //                 >
  //                   <span>{key}</span>
  //                 </span>
  //               </span>
  //             ))}
  //           </span>
  //         </div>
  //         {currentUser
  //           ? <div
  //             className="loginButtonDropDown menuList"
  //             onClick={this.handleLogout}
  //           >
  //             <div className="menuItem">
  //               <FormattedMessage id="app.logout"/>
  //             </div>
  //           </div>
  //           : <div
  //             className="loginButtonDropDown menuList"
  //             onClick={this.props.onAuthClick}
  //           >
  //             <div className="menuItem">
  //               <FormattedMessage id="app.loginOrRegister"/>
  //             </div>
  //           </div>
  //         }
  //       </div>
  //     </HeaderWrapper>
  //   )
  // }
  render () {
    const { currentUser, i18n } = this.props
    let isLineUser = false
    // const handleGoPricing = () => {
    //   window.scrollTo(0, 0)
    //   const { history } = this.props
    //   history.push('/pricing')
    // }
    const isMobile = window.innerWidth <= 768
    // let isLineUser = isLINE
    if (currentUser && currentUser.email.search('raceline+') > -1 && currentUser.email.search('@thai.run') > -1) {
      if (isLINE) {
        isLineUser = true
      }
    }
    const locale = i18n.locale

    if (isLineUser) {
      return <HeaderWrapper style={{ minWidth: '-webkit-fill-available' }}>
        <div className="header">
          <HeaderStyles>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '50%' }}>
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <img src="/logothairun-(W).png" style={{ width: '100%', maxWidth: '70px', marginLeft: '10px' }} alt="logo" />
              </Link>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
              <HeaderIconStyles>
                <i style= {{ color: '#DADADA', padding: '10px' }} className="material-icons" onClick={this.showDrawer}>dehaze</i>
                <Drawer
                  width='100%'
                  onClose={this.onClose}
                  visible={this.state.visible}
                  placement='top'
                // style={{
                //   backgroundColor: '#1c81c4'
                // }}
                // bodyStyle={{ paddingBottom: 80 }}
                >
                  <HeaderDropDownStyles style={{ padding: '0 40px' }}>
                    <span style={{ padding: '10px' }}>
                      {currentUser && [
                        <Link
                          to="/dashboard"
                          key="dashboard"
                          className="menuItem baseBtn"
                          style={{ border: '2px solid #fff', backgroundColor: 'white', color: 'black', fontWeight: '400' }}
                        >
                          <Icon type="idcard" style={{ marginRight: 10, fontSize: 20, fontWeight: 500 }} />
                          <FormattedMessage id="app.myRegistrations" defaultMessag="My Registrations" />
                        </Link>
                      ]}
                    </span>

                    <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
                    <span style={{ padding: '10px' }}>
                      <StyledMobileSelect
                        defaultValue={this.props.i18n.locale}
                        style={{
                          width: 90
                        }}
                        onChange={(locale) => this.handleLanguageChange(locale)}
                      >
                        {Object.entries(locales).map(([key, label], i) => (
                          <Option key={key} value={key}>
                            <img src={'/' + key + '.svg'} alt={key} style={{ transform: 'translateY(-1px)', height: '20px', marginTop: 4 }} />
                          </Option>
                        ))}
                      </StyledMobileSelect>
                    </span>
                    <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
                  </HeaderDropDownStyles>
                </Drawer>
              </HeaderIconStyles>
            </div>
          </HeaderStyles>
        </div>
      </HeaderWrapper>
    }
    // const menu = (
    //   <Menu>
    //     <Menu.Item key="menu1">
    //       <Link to="/dashboard">Profile</Link>
    //     </Menu.Item>
    //     <Menu.Divider />
    //     <Menu.Item key="menu4">
    //       <div onClick={this.handleLogout}>
    //         <FormattedMessage id="app.logout"/>
    //       </div>
    //     </Menu.Item>
    //   </Menu>
    // )
    return (
      <HeaderWrapper style={{ minWidth: '-webkit-fill-available' }}>
        <div className="header">
          <HeaderStyles>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '50%' }}>
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <img src={ platformObj.logo.url } style={{ width: '100%', maxWidth: '70px', marginLeft: '10px' }} alt="logo" />
              </Link>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
              {isMobile && (
                <StyledSelect
                  defaultValue={this.props.i18n.locale}
                  bordered={false}
                  onChange={(locale) => this.handleLanguageChange(locale)}
                >
                  {Object.entries(locales).map(([key, label], i) => (
                    <Option key={key} value={key}>
                      <img src={'/' + key + '.svg'} alt={key} style={{ height: '20px' }} />
                    </Option>
                  ))}
                </StyledSelect>
              )}
              <HeaderBoxStyles>
                <StyledSelect
                  defaultValue={this.props.i18n.locale}
                  bordered={false}
                  onChange={(locale) => this.handleLanguageChange(locale)}
                >
                  {Object.entries(locales).map(([key, label], i) => (
                    <Option key={key} value={key}>
                      <img src={'/' + key + '.svg'} alt={key} style={{ height: '20px' }} />
                    </Option>
                  ))}
                </StyledSelect>
                {currentUser
                  ? (
                    <Popover placement="bottomRight" content={<Profile header />} trigger="click">
                      <OrangeButton type="secondary" style={{ display: 'flex', alignItems: 'center', padding: '5px 15px' }}>
                        <p style={{ margin: '0 30px 0 0' }}>
                          {currentUser.profile.firstName} {_get(currentUser, 'profile.lastName.0', '')}.
                        </p>
                        <FontAwesomeIcon icon={faAngleDown} />
                      </OrangeButton>
                    </Popover>
                  )
                  : (
                    <>
                      <OrangeButton onClick={this.props.onAuthClick} style={{ padding: '5px 15px', margin: '0 5px' }}>
                        <FormattedMessage id="app.loginOrRegister"/>
                      </OrangeButton>
                      {/* <OrangeButton onClick={handleGoPricing} style={{ padding: '5px 15px', margin: '0 5px' }}>
                        <FormattedMessage id="app.pricing"/>
                      </OrangeButton> */}
                    </>
                  )
                }
              </HeaderBoxStyles>
              <HeaderIconStyles>
                <Popover placement="bottomRight"
                  visible={this.state.menuVisible}
                  onVisibleChange={this.handleMenuVisibleChange}
                  content={
                    <Profile onAuthClick={this.props.onAuthClick}
                      hideMenu={this.hideMenu}
                      header history={this.props.history}/>
                  } trigger="click">
                  <i style= {{ color: '#DADADA', padding: '10px' }} className="material-icons" onClick={this.showDrawer}>dehaze</i>
                </Popover>
                {/* <i style= {{ color: '#DADADA', padding: '10px' }} className="material-icons" onClick={this.showDrawer}>dehaze</i> */}
                {/* <Drawer
                  width='100%'
                  onClose={this.onClose}
                  visible={this.state.visible}
                  placement='top'
                  // style={{
                  //   backgroundColor: '#1c81c4'
                  // }}
                  // bodyStyle={{ paddingBottom: 80 }}
                >
                  <HeaderDropDownStyles style={{ padding: '0 40px' }}>
                    <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
                    <span style={{ padding: '10px' }}>
                      {currentUser
                        ? <div className="menuItem" style={{ border: '2px solid #fff', borderRadius: '20px' }}>
                          <Dropdown overlay={menu} trigger={['click']}>
                            <span className="ant-dropdown-link login_btn baseBtn logout" style={{ backgroundColor: 'white', color: 'black' }}>
                              <Icon type="user" style={{ marginRight: 10, fontSize: 18, fontWeight: 500 }} />
                              {currentUser.profile.firstName} {_get(currentUser, 'profile.lastName.0', '')}. <Icon type="down" />
                            </span>
                          </Dropdown>
                        </div>
                        : <div className="menuItem">
                          <div
                            className="login_btn baseBtn menuItem"
                            style={{
                              width: '220px',
                              border: '2px solid #fff',
                              backgroundColor: 'white',
                              color: 'black'
                            }}
                            onClick={this.props.onAuthClick}
                          >
                            <Icon type="logout" style={{ marginRight: 10, fontSize: 18, fontWeight: 500 }} />
                            <FormattedMessage id="app.loginOrRegister"/>
                          </div>
                        </div>
                      }
                    </span>
                    <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
                    <span style={{ padding: '10px' }}>
                      <div className="menuItem">
                        <div
                          className="login_btn baseBtn menuItem"
                          style={{
                            width: '220px',
                            border: '2px solid #fff',
                            backgroundColor: 'white',
                            color: 'black'
                          }}
                          onClick={() => this.handleGoPricing()}
                        >
                          <Icon type="unordered-list" style={{ marginRight: 10, fontSize: 18, fontWeight: 500 }} />
                          <FormattedMessage id="app.pricing"/>
                        </div>
                      </div>
                    </span>
                    <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
                    <span style={{ padding: '10px' }}>
                      <StyledMobileSelect
                        defaultValue={this.props.i18n.locale}
                        style={{
                          width: 90
                        }}
                        onChange={(locale) => this.handleLanguageChange(locale)}
                      >
                        {Object.entries(locales).map(([key, label], i) => (
                          <Option key={key} value={key}>
                            <img src={'/' + key + '.svg'} alt={key} style={{ transform: 'translateY(-1px)', height: '20px', marginTop: 4 }} />
                          </Option>
                        ))}
                      </StyledMobileSelect>
                    </span>
                    <div style={{ width: '300px', borderBottom: '1px solid #dfe3e8' }} />
                  </HeaderDropDownStyles>
                </Drawer> */}
              </HeaderIconStyles>
            </div>
          </HeaderStyles>
        </div>
        <div className="dropDown hideDropDown">
          <div className="menuList">
            <Link to="/" key="races" onClick={() => this.clickDropDownMenuItem()} className="menuItem">
              <FormattedMessage id="app.races"/>
            </Link>
          </div>
          {/* {currentUser &&
                <div className="menuList">
                  <Link to="/dashboard" key="dashboard" onClick={() => this.clickDropDownMenuItem()} className="menuItem">
                    <FormattedMessage id="app.myRegistrations" defaultMessag="My Registrations" />
                  </Link>
                </div>} */}
          <div className="menuList">
            <span className="localeSwitcher menuItem" >
              {Object.entries(locales).map(([key, label], i) => (
                <span key={key}>
                  {i !== 0 && <span className="localeSeparator" />}
                  <span
                    onClick={() => this.handleLanguageChange(key)}
                    className={key === locale ? 'active locale' : 'locale'}
                  >
                    <img src={'/' + key + '.svg'} alt={key} style={{ transform: 'tran, marginTop: 4slateY(-1px)', height: '20px' }} />
                  </span>
                </span>
              ))}
            </span>
          </div>
          {currentUser
            ? <div
              className="loginButtonDropDown menuList"
              onClick={this.handleLogout}
            >
              <div className="menuItem">
                <FormattedMessage id="app.logout"/>
              </div>
            </div>
            : <div
              className="loginButtonDropDown menuList"
              onClick={this.props.onAuthClick}
            >
              <div className="menuItem">
                <FormattedMessage id="app.loginOrRegister"/>
              </div>
            </div>
          }
        </div>
      </HeaderWrapper>
    )
  }
}

export default compose(
  inject('i18n'),
  Form.create(),
  // graphql(currentUser),
  withRouter,
  withApollo,
  observer
)(Header)
