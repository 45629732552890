import React, {useEffect} from 'react'
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { withRouter } from 'react-router'
import { compose } from 'recompose';


const AppUrlListener=(props)=> {
    const {history} = props

    useEffect(() => {
        const unsubscribe= App.addListener('appUrlOpen', (event: URLOpenListenerEvent ) => {
          
          const url = event.url
          const slug = url.split('.run').pop();

          console.log({url});
          console.log({slug});
          
          ////////THIS IS FOR SIGN IN CASE
          if( url.includes("signin")){
            const signInlinks = url.split(`${process.env.REACT_APP_BASE_URI}`)
            const signInlinkParams = signInlinks[1].split("signin").pop().split("&continueUrl=")[0]
            history.push(`${slug}${signInlinkParams}`)
            return
          }
          ////////THIS IS FOR SIGN UP CASE
          if( url.includes("registeration")){
            const signInlinks = url.split(`${process.env.REACT_APP_BASE_URI}`)
            const signInlinkParams = signInlinks[1].split("registeration").pop().split("&continueUrl=")[0]
            history.push(`${slug}${signInlinkParams}`)
            return
          }

          if (slug) {
            //////THIS CASE FOR PHONE AUTH
            if( slug.includes("com.googleusercontent.apps") ){
             return 
            }
            history.push(slug);
          }
        });
        return ()=>unsubscribe
      }, []);// eslint-disable-line 


    return (
    <div>

    </div>
  )
}

export default compose(withRouter)(AppUrlListener)
// export default AppUrlListener
